<template>
  <div class="page-wrap">
    <div class="head-wrap">
      <div class="search-wrap" @click="searchHandle">
        <Search
          placeholder="输入搜索词,多个词之间逗号分隔"
          readonly
          left-icon=""
          right-icon="search"
          background="transparent"
        />
      </div>
    </div>
    <div class="card-wrap">
      <div class="card-img-wrap">
        <VanImage width="100%" height="100%" fit="conver" :src="cardBg" />
      </div>
      <div class="btn-wrap">
        <Icon class-prefix="iconfont" name="dingyue" size="20" color="#007FFF" />
        <span @click="handleSubscribe">订阅的检索式</span>
      </div>
    </div>
    <div class="biomarker-wrap">
      <div class="title">生物标记物</div>
      <div class="item-wrap">
        <div class="item" v-for="item in dataList" :key="item.id" @click="handleClick(item)">
          <div class="name">{{ item.name }}</div>
          <div class="icon" v-if="item.logo_h5">
            <img :src="getUrl(item.logo_h5, item.logo_h5_stream)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search, Image as VanImage, Icon } from 'vant'
import { getBiomarkers } from '@/api/zhiku'

const router = useRouter()
const cardBg = ref(require('../../assets/zhiku-bg.png'))
const dataList = ref([])
const base64Map = new Map([
  ['png', 'data:image/png;base64,'],
  ['jpg', 'data:image/jpg;base64,'],
  ['jpeg', 'data:image/jpeg;base64,'],
  ['gif', 'data:image/gif;base64,'],
  ['bmp', 'data:image/bmp;base64,']
])

getBiomarkers().then(res => {
  if (res.code === 200) {
    dataList.value = res.data
  }
})

const getUrl = (url, stream) => {
  if (!url) {
    return null
  }
  const suffix = url.split('.').pop()
  if (stream) {
    return base64Map.get(suffix) + stream
  }
  return null
}

const searchHandle = () => {
  console.log('searchHandle')
}

const handleClick = item => {
  router.push(`/zhiku/list?biomarkerId=${item.id}&biomarkerName=${item.name}`)
}
const handleSubscribe = () => {
  router.push('/zhiku/subscribe')
}
</script>

<style lang="less" scoped>
.page-wrap {
  background: #f3f5fa;

  .biomarker-wrap {
    padding: 0 15px 20px 16px;
    margin-top: 20px;

    .title {
      width: 80px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4c5e5c;
      line-height: 22px;
    }

    .item-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 48%;
        height: 52px;
        box-sizing: border-box;
        margin-top: 12px;
        background: linear-gradient(180deg, #ffffff 0%, #e1e7ed 100%, #d9e8f6 100%, #d9e8f6 100%);
        box-shadow: 2px 2px 4px 0px #d5dbe6;
        border-radius: 6px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          width: 60%;
          font-size: 14px;
          color: #333333;
          // 文字省略
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.head-wrap {
  width: 100%;
  height: 136px;
  background: url('../../assets/bg.png') no-repeat;
  background-size: cover;
  .search-wrap {
    &:deep(.van-search__content) {
      background: rgba(4, 5, 5, 0.2);
    }
    &:deep(.van-icon-search) {
      color: #fff;
    }
    & .van-search:deep(.van-cell) {
      padding: 7px 8px 7px 0;
      background: transparent;
    }
  }
}

.card-wrap {
  padding: 0 15px 0 16px;
  margin-top: -77px;

  .card-img-wrap {
    width: 100%;
    height: 174px;
    border-radius: 10px 10px 0 0;
  }

  .btn-wrap {
    width: 100%;
    height: 40px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #007fff;

    & > span {
      margin-left: 10px;
    }
  }
}
</style>
